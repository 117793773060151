import 'swiper/css'

import { useAppContext } from '@hooks/useAppContext'
import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'
import { A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from './Button'
import styles from './SubNavBlock.module.scss'

export default function SubNavInsights({ subtext, subtitle }) {
  const { globals } = useAppContext()
  const { recents } = globals.site || {}
  const { articles } = recents || {}

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.innerSubNav}>
          <div className={styles.description}>
            {subtitle && <h3>{subtitle}</h3>}
            {subtext && <p>{subtext}</p>}
            <div className="mt-4">
              <Link href="/insights" title="View all Insights" prefetch={false}>
                <Button text="View all" opt={'dark'} />
              </Link>
            </div>
          </div>
          <div className={styles.content}>
            {/* Get the recent articles content */}
            <Swiper
              modules={[A11y]}
              slidesPerView={4}
              spaceBetween={20}
              centeredSlides={false}
              breakpoints={{
                620: {
                  slidesPerView: 2.5,
                },
                980: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 3.3,
                },
                1250: {
                  slidesPerView: 4,
                },
              }}
            >
              {articles.map((item, i) => {
                if (!item.image) return null
                return (
                  <SwiperSlide key={i}>
                    <div className={styles.navItem}>
                      <div className={styles.navCard}>
                      <Link href={`/insights/${item.slug}`} title={item.title} prefetch={false} className={styles.imageLink}>
                        <Image
                          src={urlFor(item.image.asset)
                            .crop('focalpoint')
                            .fit('crop')
                            .quality(100)
                            .width(300)
                            .format('webp')
                            .url()}
                          alt={item.title}
                          fill
                          sizes={
                            '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
                          }
                        />
                        </Link>
                      </div>
                        <h4 className={styles.infoContainer}>{item.title}</h4>
                      <div className={styles.clickThrough}>
                        <Link
                          href={`/insights/${item.slug}`}
                          title={item.title}
                          prefetch={false}
                        >
                          Read article
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  )
}
