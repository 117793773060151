import { useEffect, useRef } from 'react'

import styles from './Fields.module.scss'

const FormInput = ({ type, name, ver }) => {
  const uid = name.toLowerCase().replace(' ', '-') + '--' + type

  let style = ver.toLowerCase().replace(' ', '-') || null
  style = styles[style] || null

  let required = false

  if (name === 'email' || name === 'Email') {
    required = true
  }

  return (
    <div className={styles.formItem}>
      <label htmlFor={name} className={style}>
        {name} {required ? '*' : null}
      </label>
      <input
        id={uid}
        type={type}
        name={name}
        aria-label={name}
        className={style}
        required={required}
      />
    </div>
  )
}

const FormTextArea = ({ name, dValue, type, ver }) => {
  const uid = name.toLowerCase().replace(' ', '-') + '--' + type

  let style = ver.toLowerCase().replace(' ', '-') || null
  style = styles[style] || null

  return (
    <div className={styles.formItem}>
      <label htmlFor={name} className={style}>
        {name} *
      </label>
      <textarea
        className={`${styles.textArea} ${style}`}
        id={uid}
        name={name}
        aria-label={name}
        defaultValue={dValue}
        required
      ></textarea>
    </div>
  )
}

const FormSelectProject = () => {
  return (
    <div className={styles.formItem}>
      <label htmlFor="project-type">Project Type</label>
      <select
        id="project-type"
        name="project"
        defaultValue={'null'}
        aria-label="Project Type"
      >
        <option value="null" disabled>
          Please Select...
        </option>
        <option disabled>––– Design & Build –––</option>
        <option value="headless">Headless</option>
        <option value="bespoke shopify">Bespoke Shopify</option>
        <option value="theme setup">Theme Setup</option>
        <option disabled>––– Support –––</option>
        <option value="ad hoc">Ad Hoc</option>
        <option value="retainer">Retainer</option>
        <option value="plugin integration">Plugin integration</option>
        <option value="pagespeed optimisation">Pagespeed optimisation</option>
        <option disabled>––– Content & Marketing –––</option>
        <option value="email marketing">Email Marketing</option>
        <option value="copywriting">Copywriting</option>
        <option value="seo">SEO</option>
        <option value="other">Other</option>
      </select>
    </div>
  )
}

const FormSelectBudget = () => {
  return (
    <div className={styles.formItem}>
      <label htmlFor="budget">Budget</label>
      <select id="budget" name="budget" defaultValue={'null'}>
        <option value="null" disabled>
          Please Select...
        </option>
        <option value="1k-5k">£1,000 - £5,000</option>
        <option value="5k-10k">£5,000 - £10,000</option>
        <option value="10k-15k">£10,000 - £15,000</option>
        <option value="15k-25k">£15,000 - £25,000</option>
        <option value="25k-50k">£25,000 - £50,000</option>
        <option value="50k+">£50,000+</option>
      </select>
    </div>
  )
}

const FormHoneyPot = () => {
  const botField = useRef(null)

  useEffect(() => {
    const formMenu = document.querySelectorAll('form[name="contact--menu"]')
    const formPage = document.querySelectorAll('form[name="contact--page"]')

    const forms = [...formMenu, ...formPage]

    // console.log(forms)

    if (!forms) return

    forms.forEach((form) => {
      form.addEventListener('submit', (e) => {
        if (botField.current.value) {
          e.preventDefault()

          console.log(
            '🤖 Are you a bot?\nSorry, if you are a real person, please email us at hello@karmoon.co.uk'
          )
        }
      })
    })
  }, [])

  return (
    <div className="hidden">
      <label htmlFor="bot-field">Don't fill this out if you're human!</label>
      <input
        ref={botField}
        id="bot-field"
        name="bot-field"
        aria-label="Bot Field"
      />
    </div>
  )
}

export {
  FormHoneyPot,
  FormInput,
  FormSelectBudget,
  FormSelectProject,
  FormTextArea,
}
