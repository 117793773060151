/* eslint-disable no-unreachable */
import NewsletterForm from '@components/forms/NewsletterForm'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import styles from './PopupSignup.module.scss'

export default function PopupSignup() {
  const [signupPopup, setSignupPopup] = useCookies(['isClosed', 'isSignedUp'])

  const [isVisible, setIsVisible] = useState(false)

  let yearDateRaw = new Date()
  let weekDateRaw = new Date()

  yearDateRaw.setTime(yearDateRaw.getTime() + 365 * 24 * 60 * 60 * 1000)
  weekDateRaw.setTime(weekDateRaw.getTime() + 7 * 24 * 60 * 60 * 1000)

  const handleClose = () => {
    setSignupPopup('isClosed', true, { path: '/', expires: weekDateRaw })
    setIsVisible(false)
  }

  const handleSignup = () => {
    setSignupPopup('isSignedUp', true, { path: '/', expires: yearDateRaw })
    setIsVisible(false)
  }

  useEffect(() => {
    let timer
    const handleMouseMove = () => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        if (!signupPopup.isClosed && !signupPopup.isSignedUp) {
          setIsVisible(true)
        }
      }, 3500)
    }

    return () => {
      document.addEventListener('mousemove', handleMouseMove)
    }
  }, [handleClose, handleSignup])

  return (
    <div
      className={`${styles.container} ${isVisible ? styles.show : styles.hide}`}
    >
      <div onClick={handleClose} className={styles.closer}>
        
      </div>
      <p>
        Get access to exclusive updates, the latest news and the inside scoop 🍨
      </p>
      <NewsletterForm handleSignup={handleSignup} form={'simple'} />
    </div>
  )
}
