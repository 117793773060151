import SubNavContact from './SubNavContact'
import SubNavInsights from './SubNavInsights'
import SubNavNewsletter from './SubNavNewsletter'
import SubNavStudio from './SubNavStudio'
import SubNavWork from './SubNavWork'
// import dynamic from 'next/dynamic'

// const SubNavContact = dynamic(() => import('./SubNavContact'), { ssr: false })
// const SubNavInsights = dynamic(() => import('./SubNavInsights'), {
//   ssr: false,
// })
// const SubNavNewsletter = dynamic(() => import('./SubNavNewsletter'), {
//   ssr: false,
// })
// const SubNavStudio = dynamic(() => import('./SubNavStudio'), { ssr: false })
// const SubNavWork = dynamic(() => import('./SubNavWork'), { ssr: false })

const SubNav = ({ type, content, subtext, subtitle }) => {
  switch (type) {
    case 'Work':
      return (
        <SubNavWork content={content} subtext={subtext} subtitle={subtitle} />
      )
    case 'Insights':
      return (
        <SubNavInsights
          content={content}
          subtext={subtext}
          subtitle={subtitle}
        />
      )
    case 'Studio':
      return (
        <SubNavStudio content={content} subtext={subtext} subtitle={subtitle} />
      )
    case 'Newsletter':
      return (
        <SubNavNewsletter
          content={content}
          subtext={subtext}
          subtitle={subtitle}
        />
      )
    case 'Contact':
      return <SubNavContact subtext={subtext} subtitle={subtitle} />
    default:
      return false
  }
}

export default SubNav
