import Breadcrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import { LogoFull } from '@components/Icons'
import Nav from '@components/Nav'
import PopupSignupCTA from '@components/PopupSignupCTA'
import TouchNav from '@components/TouchNav'
import { useAppContext } from '@hooks/useAppContext'
// import useIsTouchDevice from '@hooks/useIsTouchDevice'
import { usePageType, useSubscribeModal } from '@state/store'
// import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import styles from './Header.module.scss'

// const Nav = dynamic(() => import('@components/Nav'), { ssr: false })

export default function Header({ colourText, hl }) {
  const pageType = usePageType()
  const subPopup = useSubscribeModal()
  const { globals } = useAppContext()
  const { misc } = globals.site
  const { headerButton } = misc

  // let isTouchDevice = useIsTouchDevice()
  const [scrollPosition, setScrollPosition] = useState(0)
  const dynamicRoute = useRouter().asPath

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // determine the colour of the nav items
  const switchTheme = (theme) => {
    if (theme == 'light' || theme == 'tertiary') {
      document
        .querySelectorAll('.nav-link, .main-logo-path, #burger-inner')
        .forEach((i) => {
          i.classList.add('dark-nav')
          i.classList.remove('light-nav')
        })
    } else {
      document
        .querySelectorAll('.nav-link, .main-logo-path, #burger-inner')
        .forEach((i) => {
          i.classList.add('light-nav')
          i.classList.remove('dark-nav')
        })
    }
  }

  useEffect(() => {
    // loop though eachsection that has the data theme attribute attached
    document.querySelectorAll('[data-theme]').forEach((el) => {
      let rect = el.getBoundingClientRect()
      // when the top of the section hits the offset of the header container (~100px) fire the function
      if (rect.top < 100) {
        switchTheme(el.dataset.theme)
      }
    })
  }, [dynamicRoute, scrollPosition])

  return (
    <>
      <header className={`${styles.wrapper} ${styles[pageType.getPageType()]}`}>
        <div className={`${styles.container} ${styles[colourText]}`}>
          <div className={styles.logo}>
            <Link href="/" title="Karmoon" prefetch={false}>
              <LogoFull colour={'#000'} />
            </Link>
          </div>
          <>
            <Nav colourText={colourText} />
            <div className={styles.button}>
              {pageType.getPageType() == 'article' ||
              pageType.getPageType() == 'insights' ? (
                <div
                  className="contents"
                  onClick={() => {
                    subPopup.open()
                  }}
                >
                  <Button
                    id="header-button"
                    type="button"
                    text="SUBSCRIBE FOR MORE"
                    scrolling
                    opt={'dark'}
                    customBg={hl}
                  />
                </div>
              ) : (
                (headerButton === 'startProject' && (
                  <Link href="/contact">
                    <Button
                      id="header-button"
                      type="button"
                      text="Start a project 🚀"
                      scrolling
                      opt={colourText ? colourText : 'dark'}
                      customBg={hl}
                    />
                  </Link>
                )) ||
                (headerButton === 'hiring' && (
                  <Link href="/careers">
                    <Button
                      id="header-button"
                      type="button"
                      text="We're hiring 👍"
                      scrolling
                      opt={colourText ? colourText : 'dark'}
                      customBg={hl}
                    />
                  </Link>
                ))
              )}
            </div>
          </>
          <TouchNav />
        </div>
      </header>
      <Breadcrumbs />
      <PopupSignupCTA />
    </>
  )
}
