import { useAppContext } from '@hooks/useAppContext'
import Link from 'next/link'

import styles from './FooterNav.module.scss'
import FooterSocial from './FooterSocial'

export default function FooterNav({ social }) {
  const { globals } = useAppContext()
  const data = globals.site.footer

  const handleToggle = (e) => {
    const nextUl = e.target.nextElementSibling
    if (nextUl) {
      if (nextUl.classList.contains('open')) {
        nextUl.classList.remove('open')
        nextUl.classList.add('closed')

        return
      }

      if (nextUl.classList.contains('closed')) {
        nextUl.classList.remove('closed')
        nextUl.classList.add('open')

        return
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className="md:hidden">
        <p onClick={(e) => handleToggle(e)}>Social</p>
        <FooterSocial ver={'list'} social={social} />
      </div>

      {/* dynamic nav here */}
      {data.items.map((item, i) => {
        return (
          <div key={i}>
            <p onClick={(e) => handleToggle(e)}>{item.text}</p>
            <ul className={'closed'}>
              {item.subLinks.map((sub, i) => {
                const { externalUrl, internalUrl } = sub.link || {}

                let url = internalUrl.url

                if (internalUrl?.url) {
                  const path = internalUrl.path

                  if (path === 'article') {
                    url = `/insights${internalUrl.url}`
                  } else if (path === 'project') {
                    url = `/work${internalUrl.url}`
                  } else {
                    url = `${internalUrl.url}`
                  }
                }

                return (
                  <li key={i}>
                    {externalUrl?.url && (
                      <a
                        href={`${externalUrl.url}?ref=karmoon`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {sub.linkText}
                      </a>
                    )}

                    {internalUrl?.url && (
                      <Link href={url} title={sub.linkText} prefetch={false}>
                        {sub.linkText}
                      </Link>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
