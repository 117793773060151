/* eslint-disable no-unused-vars */
import Button from '@components/Button'
import {
  FormHoneyPot,
  FormInput,
  FormSelectBudget,
  FormSelectProject,
  FormTextArea,
} from '@components/forms/Fields'
import PropTypes from 'prop-types'
import { useState } from 'react'

import styles from './ContactForm.module.scss'

export default function ContactForm({ type }) {
  // const web3forms = process.env.WEB3FORMS_API_KEY
  // const zapHookUrl = process.env.ZAPIER_WEBHOOK_URL

  let redUrl = ''

  if (typeof window === 'undefined') {
    /* we're on the server */
  } else {
    let base = ''

    const domain = window.location.hostname
    const protocol = window.location.protocol
    const port = window.location.port

    if (port) {
      base = `${protocol}//${domain}:${port}`
    } else {
      base = `${protocol}//${domain}`
    }

    redUrl = base + '/contact?thanks=true'
  }

  return (
    <section className={`${styles.wrapper} ${styles[type]}`}>
      {/* <Web3Form opt={type} api={web3forms} wh={zapHookUrl} redirect={redUrl} /> */}
      {/* <ZapForm opt={type} redirect={redUrl} /> */}
      <NetlifyForm opt={type} redirect={redUrl} />
    </section>
  )
}

ContactForm.propTypes = {
  type: PropTypes.string,
}

const NetlifyForm = ({ opt, redirect }) => {
  // test
  return (
    <form
      name="contact-new"
      method="POST"
      data-netlify="true"
      action="#"
    >
      <input type="hidden" name="form-name" value="contact-new" />

      <FormHoneyPot />

      <div className={styles.split}>
        <FormInput type="text" name="Name" ver={opt} />
        <FormInput type="text" name="Company" ver={opt} />
      </div>

      <div className={styles.split}>
        <FormInput type="email" name="Email" ver={opt} />
        <FormInput type="number" name="Phone" ver={opt} />
      </div>

      {opt !== 'menu' && (
        <div className={styles.split}>
          <FormSelectProject />
          <FormSelectBudget />
        </div>
      )}

      <FormTextArea
        type="textarea"
        name="Message"
        dValue="Dear Karmoon, I would 💌 to work with you on..."
        ver={opt}
      />

      <div className={styles.button}>
        <Button
          type="submit"
          text="Send 👋"
          aria-label="Send"
          title="Send"
          opt="dark"
        />
      </div>
    </form>
  )
}

// const Web3Form = ({ opt, api, wh, redirect }) => {
//   return (
//     <form
//       name={`contact--${opt}`}
//       action="https://api.web3forms.com/submit"
//       method="POST"
//     >
//       <input type="hidden" name="apikey" value={api} />
//       <input type="hidden" name="subject" value="New Form Submission" />
//       {/* <input type="hidden" name="webhook" value={wh} /> */}
//       <input type="hidden" name="redirect" value={redirect} />

//       <FormHoneyPot />

//       <div className={styles.split}>
//         <FormInput type="text" name="Name" ver={opt} />
//         <FormInput type="email" name="Email" ver={opt} />
//       </div>

//       <div className={styles.split}>
//         <FormInput type="text" name="Company" ver={opt} />
//         <FormInput type="number" name="Phone" ver={opt} />
//       </div>

//       {opt !== 'menu' && (
//         <div className={styles.split}>
//           <FormSelectProject />
//           <FormSelectBudget />
//         </div>
//       )}

//       <FormTextArea
//         type="textarea"
//         name="Message"
//         dValue="Dear Karmoon, I would 💌 to work with you on..."
//         ver={opt}
//       />

//       <div className={styles.button}>
//         <Button
//           type="submit"
//           text="Send 👋"
//           aria-label="Send"
//           title="Send"
//           opt="dark"
//         />
//       </div>
//     </form>
//   )
// }

// const ZapForm = ({ opt, redirect }) => {
//   const [sending, setSending] = useState(false)

//   async function handleForm(req, _res) {
//     setSending(true)

//     req.preventDefault()

//     // get form class
//     const formClass = req.target.classList

//     const data = {
//       bot: req.target[0].value,
//       name: req.target[1].value,
//       email: req.target[2].value,
//       company: req.target[3].value || 'Not specified',
//       phone: req.target[4].value || 'Not specified',
//       project: formClass.contains('long')
//         ? req.target[5].value
//         : 'Not specified' || 'Not specified',
//       budget: formClass.contains('long')
//         ? req.target[6].value
//         : 'Not specified' || 'Not specified',
//       message: formClass.contains('long')
//         ? req.target[7].value
//         : req.target[5].value || 'Not specified',
//     }

//     const result = await fetch('/api/zapier/post', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//       .then((res) => res.json())
//       .catch((err) => console.error(err))

//     if (result) {
//       setSending(false)
//       window.location.href = redirect
//     } else {
//       setSending(false)
//     }
//   }

//   return (
//     <form
//       name={`contact--${opt}`}
//       onSubmit={handleForm}
//       className={opt == 'menu' ? 'short' : 'long'}
//     >
//       <FormHoneyPot />

//       <div className={styles.split}>
//         <FormInput type="text" name="Name" ver={opt} />
//         <FormInput type="text" name="Company" ver={opt} />
//       </div>

//       <div className={styles.split}>
//         <FormInput type="email" name="Email" ver={opt} />
//         <FormInput type="number" name="Phone" ver={opt} />
//       </div>

//       {opt !== 'menu' && (
//         <div className={styles.split}>
//           <FormSelectProject />
//           <FormSelectBudget />
//         </div>
//       )}

//       <FormTextArea
//         type="textarea"
//         name="Message"
//         dValue="Dear Karmoon, I would 💌 to work with you on..."
//         ver={opt}
//       />

//       <div className={styles.button}>
//         <Button
//           type="submit"
//           text={sending ? 'Sending 🤔' : 'Send 👋'}
//           aria-label="Send"
//           title={sending ? 'Sending...' : 'Send'}
//           opt="dark"
//           disabled={sending}
//         />
//       </div>
//     </form>
//   )
// }
