/* eslint-disable no-unreachable */
import NewsletterForm from '@components/forms/NewsletterForm'
import { useSubscribeModal } from '@state/store'

import styles from './PopupSignup.module.scss'

export default function PopupSignupCTA() {

  const subPopup = useSubscribeModal()


  const handleClose = () => {
    subPopup.close()
  }

  const handleSignup = () => {
    subPopup.close()
  }


  return (
    <div
      className={`${styles.container} ${subPopup.isOpen() ? styles.show : styles.hide}`}
    >
      <div onClick={handleClose} className={styles.closer}>
        
      </div>
      <p>
        Get access to exclusive updates, the latest news and the inside scoop 🍨
      </p>
      <NewsletterForm handleSignup={handleSignup} form={'simple'} />
    </div>
  )
}
