import FooterContactDetails from '@components/FooterContactDetails'
import FooterNav from '@components/FooterNav'
import FooterSignUp from '@components/FooterSignUp'
import FooterSocial from '@components/FooterSocial'

// import dynamic from 'next/dynamic'
import styles from './Footer.module.scss'
import { LogoFullNoEffect } from './Icons'

// const FooterNav = dynamic(() => import('@components/FooterNav'), { ssr: false })
// const FooterSignUp = dynamic(() => import('@components/FooterSignUp'), {
//   ssr: false,
// })
// const FooterSocial = dynamic(() => import('@components/FooterSocial'), {
//   ssr: false,
// })
// const FooterContactDetails = dynamic(
//   () => import('@components/FooterContactDetails'),
//   { ssr: false }
// )

export default function Footer() {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.row}>
          <FooterSignUp />
          <FooterContactDetails />
        </div>

        <span className={styles.divider} />

        <div className={styles.row}>
          <FooterSocial ver={'title'} />
          <FooterNav />
        </div>

        <span className={`${styles.divider} md:hidden`} />

        <div className={styles.row}>
          <div className="w-1/4 md:w-full" data-flex="false">
            <div className="w-full sm:w-12 xl:w-16">
              <LogoFullNoEffect />
            </div>
          </div>

          <div
            className="w-full flex justify-end md:justify-start"
            data-flex="false"
          >
            <p className="text-xs xl:text-sm text-right md:text-left m-0 p-0">
              © {new Date().getFullYear()} Karmoon Limited. <br className='block md:hidden' />All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
