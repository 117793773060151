// import { LongArrow } from '@components/Icons'
import Head from 'next/head'
// import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

// import styles from './Breadcrumbs.module.scss'

// const convertBreadcrumb = (string) => {
//   const varRegex = /(?:\/)([^#]+)(?=#|$)/gi
//   const queRegex = /\?/gi
//   const genRegex = /\s[a-zA-Z]$/gi

//   return string
//     .replace(/-/g, ' ')
//     .replace(/oe/g, 'ö')
//     .replace(/ae/g, 'ä')
//     .replace(/ue/g, 'ü')
//     .replace(varRegex, '')
//     .replace(queRegex, '')
//     .replace(genRegex, '')
// }

const Breadcrumbs = () => {
  const router = useRouter()
  const [breadcrumbs, setBreadcrumbs] = useState(null)

  useEffect(() => {
    if (router) {
      let s
      // remove anything that occurs after the ? char
      // since the query object will always have a slug by default - check to see if its a queried page
      if (Object.keys(router.query).length > 1) {
        s = router.asPath.substring(0, router.asPath.indexOf('?'))
      } else {
        s = router.asPath
      }

      const linkPath = s.split('/')

      linkPath.shift()

      const pathArray = linkPath.map((path, i) => {
        // console.log(path)
        return {
          breadcrumb: path,
          href: '/' + linkPath.slice(0, i + 1).join('/'),
        }
      })

      setBreadcrumbs(pathArray)
    }
  }, [router])

  if (!breadcrumbs) {
    return null
  }

  // generate the seo structured data for breadcrumbs across the site
  const GetBreadcrumbStructuredData = () => {
    let _content = breadcrumbs.map((crumb, i) => {
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: crumb.breadcrumb || 'Karmoon',
        item: `https://karmoon.co.uk${crumb.href}`,
      }
    })
    return [
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [..._content],
      },
    ]
  }

  GetBreadcrumbStructuredData()

  return (
    <>
      <Head>
        <script type="application/ld+json" key="breadcrumb-data">
          {JSON.stringify(GetBreadcrumbStructuredData())}
        </script>
      </Head>
      {/* <div className={`${styles.crumbs} breadcrumbs hidden`}>
        <div className="container">
          <ul>
            {breadcrumbs.map((breadcrumb) => {
              return (
                <li key={breadcrumb.href}>
                  <Link href={breadcrumb.href} title={convertBreadcrumb(breadcrumb.breadcrumb)}>
                    {convertBreadcrumb(breadcrumb.breadcrumb)}
                  </Link>
                  <LongArrow colour="#000" />
                </li>
              )
            })}
          </ul>
        </div>
      </div> */}
    </>
  )
}

export default Breadcrumbs
