/* eslint-disable no-unused-vars */

import { SubmitArrow } from '@components/Icons'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRef,useState } from 'react'
import { useForm } from 'react-hook-form'

import styles from './NewsletterForm.module.scss'

export default function NewsletterForm({ form, handleSignup }) {
  let type = form === 'full' ? 'full' : 'simple'

  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [signupName, setSignupName] = useState('')
  
  const nameRef = useRef(null);

  const octopusListID = process.env.OCTOPUS_LIST_ID

  // console.log('Octopus List ID', octopusListID)
  // console.log(id)

  const handleAccept = () => {
    setIsAccepted(!isAccepted);
  }

  const handleName = (n) => {
    setSignupName(nameRef.current.value)
  }

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  const hasAgreed = watch('acceptTerms')

  const resetForm = (e) => {
    e.preventDefault()
    reset()
    setError(false)
    setSuccess(false)
    setSubmitting(false)
    setIsAccepted(false)
    setSignupName('')
  }

  const email = register('email', {
    required: 'This field is required.',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address.',
    },
  })

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (!octopusListID) setError(true)
    if ((!hasAgreed && !octopusListID) || !octopusListID) return

    setSubmitting(true)
    setError(false)

    // window.analytics.track('Mailing List Signup', {
    //   octopusListID,
    // })

    fetch('/api/octopus/subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        listID: octopusListID,
        email: data.email,
        fullname: data.fullname || '',
        acceptTerms: data.acceptTerms,
        name: data.name || signupName,
        tags: ['newsletter', 'weekly', 'themes'],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setSubmitting(false)
        setSuccess(true)
      })
      .catch((error) => {
        setSubmitting(false)
        setError(true)
        // console.log(error)
      })

    // handleSignup()
  }

  const SuccessMessage = () => {
    return (
      <>
        <h4 className='form-alert'>Success!</h4>
        <p>We are delighted to have you with us.</p>
      </>
    )
  }

  const ErrorMessage = () => {
    return (
      <>
        <h4 className='form-alert'>Error!</h4>
        <p className='form-message'>Looks like you're already subscribed.</p>
        <button onClick={(e) => resetForm(e)}>Try again</button>
      </>
    )
  }

  return (
    <>
      {/* {status === 'sending' && <div>Sending...</div>} */}
      {/* {status === 'error' && (
        // NOTE: Don't add custom error messages as they're unique to the case
        // TODO: Error message styling
        // TODO: Fix error message for empty fields
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === 'success' && (
        // TODO: Add custom success message if you want
        // TODO: Success message styling
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )} */}
      {submitting && <div>Sending...</div>}

      {success && (
        <div className={`${styles.formStatus} ${styles.success}`}>
          <SuccessMessage />
        </div>
      )}

      {error && (
        <div className={`${styles.formStatus} ${styles.error}`}>
          <ErrorMessage />
        </div>
      )}

      {!success && (
        // NOTE: Form will hide completely on succesful submission
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            name="fullname"
            autoComplete="off"
            aria-hidden="true"
            {...register('fullname')}
            className="hidden"
          />

          <div className={styles.inputGroup}>
            {type == 'full' && (
              <input
                type="text"
                placeholder="Name"
                ref={nameRef}
                onChange={(e) => handleName(e)}
              />
            )}

            <input
              name="email"
              type="email"
              placeholder={errors?.email ? ' ' : 'Email address'}
              inputMode="email"
              autoComplete="email"
              ref={email.ref}
              // onChange={(e) => handleEmail(e)}
              onBlur={(e) => {
                email.onBlur(e)
              }}
              onChange={(e) => {
                email.onChange(e)
              }}
            />

            {errors?.email && (
              <span
                role="alert"
                id="error-message"
                className="text-xs text-[red] absolute right-[100px] top-2/4 translate-y-[-50%]"
              >
                {errors.email.message}
              </span>
            )}

            <button
              disabled={submitting}
              aria-label="Sign up"
              title="Sign up to our newsletter"
              className={`${isAccepted ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-20'}`}
            >
              {type === 'full' ? 'Sign up' : <SubmitArrow />}
            </button>
          </div>

          <label className={styles.label}>
            <input
              name="acceptTerms"
              type="checkbox"
              onClick={handleAccept}
              required
              {...register('acceptTerms')}
            />
            <span>
              I've read and accepted the{' '}
              <Link
                href="/privacy-policy"
                title="Privacy Policy"
                prefetch={false}
              >
                Karmoon Privacy Policy
              </Link>
            </span>
          </label>
          {/* {isAccepted ? 'accepted' : 'not accepetd'} */}
        </form>
      )}
    </>
  )
}

NewsletterForm.propTypes = {
  form: PropTypes.oneOf(['full', 'simple']),
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func,
}
