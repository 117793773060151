import SubNav from '@components/SubNav'
import { useAppContext } from '@hooks/useAppContext'
import { useMegaMenu } from '@state/store'
// import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import styles from './Nav.module.scss'

// const SubNav = dynamic(() => import('@components/SubNav'), { ssr: false })

export default function Nav({ colourText }) {
  const { globals } = useAppContext()
  const { mainNav } = globals.site.nav

  const dynamicRoute = useRouter().asPath

  const menuState = useMegaMenu()

  useEffect(() => {
    menuState.setMenuState(false)
  }, [dynamicRoute])

  return (
    <div className={`navigation ${styles.wrapper}`}>
      <div className={`${styles.navWrapper} ${styles[colourText]}`}>
        <ul>
          {mainNav &&
            mainNav.map((n, i) => {
              const { subtext, subtitle } = n || {}
              return (
                <li
                  className={`${styles.navItem} ${
                    menuState.isMenuPaused() ? styles.killHover : ''
                  }`}
                  key={i}
                  onMouseOver={
                    n.hasSubNav
                      ? () => {
                          menuState.setMenuState(true)
                          document
                            .querySelector('#main-content')
                            .classList.add('blur')
                          document.querySelector(
                            '#main-content'
                          ).style.opacity = 0.7
                        }
                      : () => {
                          document
                            .querySelector('#main-content')
                            .classList.add('blur')
                          document.querySelector(
                            '#main-content'
                          ).style.opacity = 1
                        }
                  }
                  onMouseOut={() => {
                    menuState.setMenuState(false)
                    document
                      .querySelector('#main-content')
                      .classList.remove('blur')
                    document.querySelector('#main-content').style.opacity = 1
                  }}
                  onClick={() => {
                    menuState.setMenuState(false)
                  }}
                >
                  <Link
                    href={n.navigationItemUrl.internalLink.internalUrl}
                    className={`${styles.navItem} ${
                      menuState.isMenuOpen() && styles.open
                    } nav-link`}
                    title={n.text}
                    prefetch={false}
                  >
                    {n.text}
                  </Link>
                  {n.hasSubNav && (
                    <SubNav
                      subtext={subtext}
                      subtitle={subtitle}
                      type={n.text}
                      content={n.subNav}
                    />
                  )}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
