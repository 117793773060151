/*
████████╗███████╗ █████╗ ███╗   ███╗
╚══██╔══╝██╔════╝██╔══██╗████╗ ████║
   ██║   █████╗  ███████║██╔████╔██║
   ██║   ██╔══╝  ██╔══██║██║╚██╔╝██║
   ██║   ███████╗██║  ██║██║ ╚═╝ ██║
   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝
 ██████╗ █████╗ ██████╗ ██████╗ 
██╔════╝██╔══██╗██╔══██╗██╔══██╗
██║     ███████║██████╔╝██║  ██║
██║     ██╔══██║██╔══██╗██║  ██║
╚██████╗██║  ██║██║  ██║██████╔╝
 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ 
*/
// Renders a single team member card with name, role, and image

import { urlFor } from '@lib/sanity'
import Image from 'next/image'

import styles from './TeamCard.module.scss'

export default function TeamCard({ content }) {
  const { name, role, image } = content

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageContainer}>
        {image?.asset && (
          <Image
            src={urlFor(image.asset)
              .crop('entropy')
              .fit('crop')
              .quality(90)
              .height(365)
              .format('webp')
              .url()}
            alt={name}
            fill
            sizes="(min-width: 768px) 50vw, 100vw"
          />
        )}
      </div>
      <div className={styles.textContainer}>
        <span>{name}</span>
        <h4>{role}</h4>
      </div>
    </div>
  )
}
