import NewsletterForm from '@components/forms/NewsletterForm'

import styles from './FooterSignUp.module.scss'

export default function FooterSignUp() {
  const content = {
    title: '',
    body: 'Get access to exclusive updates, the latest news and the inside scoop 🍨',
  }

  return (
    <div className={styles.wrapper}>
      {content.body && <p>{content.body}</p>}

      <NewsletterForm form={'simple'} />
    </div>
  )
}
