import Burger from '@components/Burger'
import SubNav from '@components/SubNav'
import { useAppContext } from '@hooks/useAppContext'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import Button from './Button'
import { Facebook, Insta, Linkedin, Twitter} from './Icons'
import styles from './TouchNav.module.scss'

export default function TouchNav() {
  const { globals } = useAppContext()
  const { social } = globals.site
  const { mainNav } = globals.site.nav

  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  useEffect(() => {
    if (navbarOpen) {
      document.body.classList.add('body-lock')
    } else {
      document.body.classList.remove('body-lock')
    }
  }, [navbarOpen])

  return (
    <div className={`navigation ${styles.wrapper}`}>
      <div
        className={`${styles.navWrapper} ${
          navbarOpen ? styles.open : styles.closed
        }`}
      >
        <ul>
          {mainNav &&
            mainNav.map((n, i) => {
              const { subtext, subtitle } = n || {}
              return (
                <li
                  className={styles.navItem}
                  key={i}
                  onMouseOver={
                    n.hasSubNav
                      ? () => {
                          document.querySelector(
                            '#main-content'
                          ).style.opacity = 0.2
                        }
                      : () => {
                          document.querySelector(
                            '#main-content'
                          ).style.opacity = 1
                        }
                  }
                  onMouseOut={() => {
                    document.querySelector('#main-content').style.opacity = 1
                  }}
                  onClick={handleToggle}
                >
                  <Link href={n.navigationItemUrl.internalLink.internalUrl} prefetch={false}>
                    {n.text}
                  </Link>
                  {n.hasSubNav &&  (
                    <SubNav
                      subtext={subtext}
                      subtitle={subtitle}
                      type={n.text}
                      content={n.subNav}
                    />
                  )}
                </li>
              )
            })}
        </ul>
        <div className='flex justify-center mt-8'>
          <Image className={styles.bagmanMobile} src="/img/bagman-local.png" alt="karmoon" width={180} height={138} />
        </div>
        <div className='flex justify-center mt-8'>
          <Link href="/contact" prefetch={false}>
        <Button text="Start  a project  🚧" scrolling opt="light" />
        </Link>
        </div>
        <div className='flex justify-center items-center gap-4 mt-8'>
         <div><a href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noreferrer"><Twitter /></a></div>
          <div><a href={`https://instagram.com/${social.instagram}`} target="_blank" rel="noreferrer"><Insta /></a></div>
          <div><a href={`https://linkedin.com/company/${social.linkedin}`} target="_blank" rel="noreferrer"><Linkedin /></a></div>
          <div><a href={`https://www.facebook.com/${social.facebook}`} target="_blank" rel="noreferrer"><Facebook /></a></div>
          {/* <div><a><Pinterest /></a></div>
          <div><a><Youtube /></a></div> */}
        </div>
        
      </div>
      <Burger action={handleToggle} status={navbarOpen} />
    </div>
  )
}
