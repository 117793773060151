/* eslint-disable no-unused-vars */
import 'swiper/css'

import Button from '@components/Button'
import NewsletterForm from '@components/forms/NewsletterForm'
import { useAppContext } from '@hooks/useAppContext'
import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'
import { A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './SubNavBlock.module.scss'

export default function SubNavNewsletter({ subtext, subtitle }) {
  const { globals } = useAppContext()
  const { recents } = globals.site || {}
  const { newsletters } = recents || {}

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.innerSubNav}>
          <div className={`${styles.description}`}>
            {subtitle && <h3>{subtitle}</h3>}
            {subtext && <p>{subtext}</p>}
            <div className="mt-4">
              <Link href="/newsletters" title="Karmoon newsletters" prefetch={false}>
                <Button text="View all" opt={'dark'} />
              </Link>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <NewsletterForm form={'full'} /> 
          </div>
        </div>
      </section>
    </div>
  )
}
