import styles from './FooterContactDetails.module.scss'

export default function FooterContactDetails() {
  return (
    <div className={styles.wrapper}>
      <p>
        For any enquiries please email us:{' '}
        <a href="mailto:hello@karmoon.co.uk">hello@karmoon.co.uk</a>
      </p>
      <p>+44 (0) 7468 486 568</p>
      {/* <address>Studio 14, 19 Lever Street, Manchester, M1 1AN, UK</address> */}
    </div>
  )
}
