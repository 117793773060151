import 'swiper/css'

import { useAppContext } from '@hooks/useAppContext'
import { urlFor } from '@lib/sanity'
import Image from 'next/image'
import Link from 'next/link'
import { A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from './Button'
import styles from './SubNavBlock.module.scss'

export default function SubNavWork({ subtext, subtitle }) {
  const { globals } = useAppContext()
  const { recents } = globals.site || {}
  const { projects } = recents || {}

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.innerSubNav}>
          <div className={styles.description}>
            {subtitle && <h3>{subtitle}</h3>}
            {subtext && <p>{subtext}</p>}
            <div className="mt-4">
              <Link href="/work" title="View all projects" prefetch={false}>
                <Button text="View all" opt={'dark'} />
              </Link>
            </div>
          </div>
          <div className={styles.content}>
            {/* Get the recent projects content */}
            <Swiper
              modules={[A11y]}
              slidesPerView={4}
              spaceBetween={20}
              centeredSlides={false}
              breakpoints={{
                620: {
                  slidesPerView: 2.5,
                },
                980: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 3.3,
                },
                1250: {
                  slidesPerView: 4,
                },
              }}
            >
              {projects &&
                projects.map((p, i) => {
                  const { title, slug, image } = p
                  if (!image) return null
                  return (
                    <SwiperSlide key={i}>
                      <div className={styles.navItem}>
                        <div className={styles.navCard}>
                          <Link href={slug} title={title} prefetch={false} className={styles.imageLink}>
                          <Image
                            src={urlFor(image.asset)
                              .crop('focalpoint')
                              .fit('crop')
                              .quality(90)
                              .sharpen(12)
                              .dpr(1)
                              .height(300)
                              .format('webp')
                              .url()}
                            alt={p.title}
                            fill
                            sizes={
                              '(max-width: 320px) 200px, (max-width: 480px) 300px, (max-width: 640px) 400px, (max-width: 800px) 600px, 100vw'
                            }
                          />
                          </Link>
                        </div>
                          <p className={styles.infoContainer}>{title}</p>
                          <Link className={`${styles.clickThrough} ${styles.workClick}`} href={slug} title={title} prefetch={false}>
                            View project
                          </Link>
                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  )
}
