import PropTypes from 'prop-types'

import styles from './Button.module.scss'

export default function Button({
  text,
  type,
  scrolling,
  opt,
  outline,
  id,
  customBg,
}) {
  let classes = `${styles.button} ${outline && `${styles.outline}`} ${
    scrolling ? styles.scrolling : styles.static
  } ${!customBg && `${styles[opt]}`}`

  return scrolling ? (
    <>
      <button
        type={type ? type : null}
        className={classes}
        id={id}
        style={{ backgroundColor: customBg }}
      >
        {customBg && <span className={styles.fade}></span>}
        <div className={styles.marquee}>
          <div className={styles.track} id={styles.mone}>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
          </div>

          <div className={styles.track} id={styles.mtwo}>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
          </div>

          <div className={styles.track} id={styles.mthree}>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
          </div>

          <div className={styles.track} id={styles.mfour}>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
            <span className="button-text">{text}</span>
          </div>
        </div>
      </button>
    </>
  ) : (
    <button
      type={type ? type : null}
      className={classes}
      id={id}
      style={{ backgroundColor: customBg }}
    >
      {customBg && <span className={styles.fade}></span>}
      {text}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  scrolling: PropTypes.bool,
  opt: PropTypes.oneOf(['light', 'dark', 'contrast']),
  outline: PropTypes.bool,
  id: PropTypes.string,
}
