import styles from './Burger.module.scss'

export default function Burger({ status, action }) {
  return (
    <button
      className={`${styles.hamburger} ${status && styles.isActive}`}
      type="button"
      onClick={action}
      title="Menu"
      aria-label="Mobile site navigation toggle"
    >
      <span className={styles.burgerBox}>
        <span id="burger-inner" className={styles.burgerInner}></span>
      </span>
    </button>
  )
}
