import 'swiper/css'

import Button from '@components/Button'
import TeamCard from '@components/TeamCard'
import { useAppContext } from '@hooks/useAppContext'
import Link from 'next/link'
import { A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './SubNavBlock.module.scss'

export default function SubNavStudio({ subtext, subtitle }) {
  const { globals } = useAppContext()
  const { team } = globals.site || {}

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.innerSubNav}>
          <div className={styles.description}>
            {subtitle && <h3>{subtitle}</h3>}
            {subtext && <p>{subtext}</p>}
            <div className="mt-4">
              <Link href="/studio" title="Studio" prefetch={false}>
                <Button text="The Studio" opt={'dark'} />
              </Link>
            </div>
          </div>
          <div className={styles.content}>
          <Swiper
              modules={[A11y]}
              slidesPerView={3.4}
              spaceBetween={20}
              centeredSlides={false}
              breakpoints={{
                620: {
                  slidesPerView: 2.5,
                },
                980: {
                  slidesPerView: 2.5,
                },
                1024: {
                  slidesPerView: 2.5,
                },
                1250: {
                  slidesPerView: 3.4,
                },
              }}
            >
            {team &&
              team.map((t, i) => {
                return (
                  <SwiperSlide key={i}>
                    <TeamCard content={t} />
                  </SwiperSlide>
                )
              })}
              </Swiper>
          </div>
        </div>
      </section>
    </div>
  )
}
