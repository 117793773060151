import Footer from '@components/Footer'
import Header from '@components/Header'
import PopupSignup from '@components/PopupSignup'
import { usePageType } from '@state/store'

// import dynamic from 'next/dynamic'
import styles from './Layout.module.scss'

const Layout = ({ children, colourText, hl }) => {
  const pageType = usePageType()

  return (
    <>
   <Header colourText={colourText} hl={hl} />
      <main
        id="main-content"
        className={`${styles.main} ${
          pageType.getPageType() == 'work' ||
          pageType.getPageType() == 'landing' ||
          pageType.getPageType() == 'article'
            ? styles.light
            : ''
        }`}
      >
        <PopupSignup />
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
