import { useAppContext } from '@hooks/useAppContext'
import PropTypes from 'prop-types'

import styles from './FooterSocial.module.scss'

export default function FooterSocial({ ver }) {
  const { globals } = useAppContext()
  const { social } = globals.site

  switch (ver) {
    case 'title':
      return (
        <div className={styles.wrapper}>
          <p className={styles.heading}>Social</p>
          <SocialLinks social={social} />
        </div>
      )

    case 'list':
      return <SocialLinks social={social} />

    default:
      return null
  }
}

const SocialLinks = ({ social }) => {
  return (
    <ul className="closed">
      {social.twitter && (
        <li>
          <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>
        </li>
      )}
      {social.instagram && (
        <li>
          <a href={`https://instagram.com/${social.instagram}`}>Instagram</a>
        </li>
      )}
      {social.linkedin && (
        <li>
          <a href={`https://linkedin.com/company/${social.linkedin}`}>
            LinkedIn
          </a>
        </li>
      )}
    </ul>
  )
}

FooterSocial.propTypes = {
  ver: PropTypes.string.isRequired,
}
